.skills_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    @include tablet-down {
        gap: 20px;
    }

}

.skills_description {
    font-family: 'Boogie';
    font-size: 25px;
    color: $navy;
    @include mobile-down {
        font-size: 20px;
    }
}

.skill_icon{
    height: 50px;
    width: 50px;
    object-fit: cover;
}
