.card_wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    width: 28%;
    border-radius: 50px;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    background-color: $cream;

    &:hover {
        background-color: $navy;
        transform: scale(1.2);
        border-color: $navy;

        .card_picture {
            transform: scale(1.2);
            transition: all 0.4s ease-in-out;
            filter: opacity(20%);
        }

        .card_title,
        .card_description_wrapper {
            visibility: visible;
            opacity: 1;
        }
    }

    @include tablet-down {
        border-radius: 30px;
        width: 40%;
    }

    @include mobile-down {
        border-radius: 20px;
        width: 70%;
    }
}

.card_picture {
    width: 100%;
}

.card_details {
    font-family: 'Boogie';
    color: $cream;
    font-size: 16px;
    pointer-events: none;
    padding-left: 5px;
    padding-top: 5px;
    padding-right: 1px;

    @include tablet-down {
        font-size: 15px;
    }

    @include mobile-down {
        font-size: 15px;
    }
}

.card_description_wrapper {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    gap: 5px;

    @include tablet-down {
        gap: 4px;
    }

    @include mobile-down {
        gap: 3px;
    }
}

.card_description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card_title {
    font-family: "Boogie";
    font-size: 18px;
    color: $purple;
    padding-bottom: 4px;
    pointer-events: none;

    @include tablet-down {
        font-size: 15px;
        padding-bottom: 3px;
    }

    @include mobile-down {
        font-size: 12px;
    }
}

.card_text {
    font-family: "Boogie";
    font-size: 18px;
    color: $cream;
    pointer-events: none;

    @include tablet-down {
        font-size: 12px;
        padding-bottom: 0;
    }
}

.card_icon_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5px;
}

.card_icon_link {
    width: 10%;
    cursor: pointer;
}

.card_icon {
    width: 100%;
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.1);
        transition: all 0.4s ease-in-out;
        -webkit-filter: opacity(70%);
        filter: opacity(70%);
    }
}