.aboutDetails_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 30px;
    gap: 100px;

    @include tablet-down {
        flex-direction: column;
        gap: 20px;
        padding-top: 0;
    }

    @include mobile-down {
        flex-direction: column;
        gap: 20px;
        padding-top: 0;
    }
}

.about_picture {
    width: 25%;
    border-radius: 50px;

    @include tablet-down {
        width: 50%;
    }
}

.about_description {
    font-family: 'Boogie';
    font-size: 35px;
    color: $navy;
    width: 45%;

    @include tablet-down {
        width: 90%;
        text-align: center;
    }

    @include mobile-down {
        width: 90%;
        font-size: 20px;
        text-align: center;
    }
}