.header_wrapper {
    position: sticky;
    z-index: 5;
    top: 0;
    display: flex;
    flex-direction: row;
    height: 137px;
    justify-content: space-between;
    background-image: url('../../assets/circuit.png');
    background-position: center;
    .title_header{
        display: flex;
        font-size: 33px;
        align-items: center;
        color: #BCB4E6;
        justify-items: center;
        font-family: "Boogie";

        @include tablet-down {
            font-size: 25px;
        }
    
        @include mobile-down {
            font-size: 15px;
        }
    }
    &::after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($color: $cream, $alpha: 0.5);
    }

    @include mobile-down {
        height: 60px;
    }
}

.header_background {
    width: 100%;
}

.nav_wrapper {
    display: flex;
    align-items: flex-end;
    gap: 20px;
    padding-right: 20px;
    width: 70%;
    z-index: 2;
}

nav {
    display: flex;
    justify-content: flex-end;
    gap: 50px;
    flex-direction: row;
    width: 100%;

    @include tablet-down {
        gap: 40px;
    }

    @include mobile-down {
        gap: 15px;
    }
}

.nav_link {
    font-family: 'Boogie';
    text-decoration: none;
    font-size: 33px;
    color: $navy;
    transition: all 0.3s;

    @include tablet-down {
        font-size: 30px;
    }

    @include mobile-down {
        font-size: 18px;
    }
}

.nav_link:hover {
    color: $purple;
    transform: scale(1.1);
}

.active {
    color: $darkpink;

    @include tablet-down {
        color: $navy;
    }
}

