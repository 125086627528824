.projetsCards_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 55px;
    justify-content: center;
    width: 90%;
    padding: 20px;

    @include tablet-down {
        gap: 30px;
    }

    @include mobile-down {
        flex-direction: column;
        align-items: center;
    }
}