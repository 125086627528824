@import '../utils/mixin';
@import '../utils/variables';
@import '../base/font';

html{
    scroll-behavior: smooth;
}

body {
    background-color: $cream;
}

h2 {
    font-family: 'Boogie';
    font-size: 45px;
    padding-bottom: 50px;
    padding-top: 35px;
    color: $purple;

    @include tablet-down {
        font-size: 40px;
        padding-bottom: 30px;
        padding-top: 0;
    }

    @include mobile-down {
        font-size: 30px;
        padding-bottom: 20px;
        padding-top: 20px;
    }
}

.padding_section {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;

    @include mobile-down {
        padding-top: 15px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.home_wrapper {
    background-image: #b17931;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.anchor{
    display: block;
    height: 137px;
    margin-top: -137px;
    visibility: hidden;

    @include mobile-down {
        height: 60px;
        margin-top: -60px;
    }
  }

  .about_wrapper,
  .projets_wrapper,
  .skills_wrapper,
  .contact_wrapper  {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}