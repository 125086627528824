// -- Colors palette

$cream: #FFFCF7;
$darkcream: #F1E6D6;
$yellow: #FFDC8A;
$lightpink: #FCF1EC;
$pink: #E999A6;
$darkpink: red;
$lilac: #D3D0E4;
$purple: #BCB4E6;
$navy: #1E0247;

// -- Media queries breakpoints
$xs-max: 600px;
$sm-min: $xs-max + 1px;
$sm-max: 900px;
$md-min: $sm-max + 1px;
$md-max: 1200px;
$lg-min: $md-max + 1px;
$lg-max: 1440px;
$xl-min: $lg-max + 1;
$screen-xs-max: '(max-width: #{$xs-max})';
$screen-sm-min: '(min-width: #{$sm-min})';
$screen-sm-max: '(max-width: #{$sm-max})';
$screen-sm-only: '#{$screen-sm-min} and #{$screen-sm-max}';
$screen-md-min: '(min-width: #{$md-min})';
$screen-md-max: '(max-width: #{$md-max})';
$screen-md-only: $screen-md-min + ' and ' + $screen-md-max;
$screen-lg-min: '(min-width: #{$lg-min})';
$screen-lg-max: '(max-width: #{$lg-max})';
$screen-xl-min: '(min-width: #{$xl-min})';